export type FormatCurrencyOptions = {
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  fallbackValue?: string;
};

const formatCurrency = (
  value: number | undefined | null,
  options?: FormatCurrencyOptions,
) => {
  const mergedOptions = {
    locale: 'fi',
    style: 'currency',
    currency: 'EUR',
    fallbackValue: '',
    ...options,
  };

  if (typeof value !== 'number' || Number.isNaN(value)) {
    return mergedOptions.fallbackValue;
  }

  // if value isn't an integer, and we didn't get
  // a value for minimumFractionDigits (or it's set to 0),
  // then force it to 2, since otherwise we could end up
  // rendering 100,5 € which looks stupid
  if (value % 1 !== 0 && mergedOptions.minimumFractionDigits === undefined) {
    mergedOptions.minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat(mergedOptions.locale, mergedOptions).format(
    value,
  );
};

export default formatCurrency;
